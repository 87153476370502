<template>
  <common-form
    #default="{ isValidForm }"
    @submit="submitData"
  >
    <pp-frame>
      <common-loading
        :loading="!qSections ||
          loadingCompletedSections || loadingHistory"
      >
        <v-alert
          v-if="qSections && qSections.history"
          type="success"
        >
          {{ $t('This page has been completed.') }}<br>
          {{ translate(`
          If you need to make changes to this page,
          please contact DOCTOR at somewhere!
          `) }}
        </v-alert>
        <template v-else>
          <h2 class="blue-grey--text">
            {{ $t('Premedication') }}
          </h2>
          <div>
            {{ translate(`
            Have you been told you should receive pre medication
            before dental procedures?
            `) }}
            <common-toggle
              v-model="havePreMedication"
              class="ml-3"
            />
          </div>
          <v-textarea
            v-if="havePreMedication"
            v-model="preMedication"
            :label="medicationLabel"
            class="mt-5"
            rows="4"
            dense
            outlined
          />

          <h2 class="blue-grey--text mt-6">
            {{ $t('Allergens') }}
          </h2>
          <div>
            {{ translate(`
            Do you have any known allergens
            (for example: aspirin, latex, penicillin, etc)?
            `) }}
            <common-toggle
              v-model="haveAllergens"
              class="ml-3"
            />
          </div>
          <v-row v-if="haveAllergens">
            <v-col
              v-for="(allergen, idx) in allergens"
              :key="`allergen-${idx}`"
              class="my-0 py-0 d-flex align-stretch"
              :order="$vuetify.breakpoint.smAndDown && (idx % 2)
                ? 'last' : 'first'"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="allergen.value"
                class="my-0"
                :label="allergen.allergens"
                hide-details
              />
            </v-col>
          </v-row>
          <v-textarea
            v-if="haveAllergens"
            v-model="otherAllergens"
            :label="allergicListLabel"
            class="mt-5"
            rows="4"
            outlined
          />

          <h2 class="blue-grey--text mt-6">
            {{ $t('Medications currently being taken') }}
          </h2>
          <div>
            {{ $t('Are you currently taking any medications?') }}
            <common-toggle
              v-model="haveMedication"
              class="ml-3"
            />
          </div>
          <v-row v-if="haveMedication">
            <v-col
              v-for="(medication, idx) in medications"
              :key="`medication-${idx}`"
              class="my-0 py-0 d-flex align-stretch"
              :order="$vuetify.breakpoint.smAndDown && (idx % 2)
                ? 'last' : 'first'"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="medication.value"
                class="my-0"
                :label="medication.medications"
                hide-details
              />
            </v-col>
          </v-row>
          <v-textarea
            v-if="haveMedication"
            v-model="otherMedications"
            :label="medicationListLabel"
            class="mt-5"
            rows="4"
            outlined
          />

          <h2 class="blue-grey--text mt-6">
            {{ $t('Medical History') }}
          </h2>
          <v-row v-if="medicalHistories.length">
            <v-col
              v-for="(history, idx) in medicalHistories"
              :key="`history-${idx}`"
              class="my-0 py-0 d-flex align-stretch"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="history.value"
                class="ma-0"
                :label="history.history"
                hide-details
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="otherHistory"
            :label="diagnosesListLabel"
            class="mt-5"
            rows="4"
            outlined
          />

          <h2 class="blue-grey--text mt-6">
            {{ $t('Dental History') }}
          </h2>
          <v-row>
            <v-col
              class="my-0 mt-6 py-0"
              cols="12"
              md="6"
            >
              <v-select
                v-model="dentalHealth"
                :label="dentalHealthLabel"
                :items="dentalHealthQuality"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row v-if="dentalHistories.length">
            <v-col
              v-for="(history, idx) in dentalHistories"
              :key="`dhistory-${idx}`"
              class="d-flex align-stretch"
              cols="12"
              md="6"
            >
              <v-card class="flex-grow-1 d-flex flex-column">
                <v-card-text class="flex-grow-1 pt-0 d-flex flex-column">
                  <v-checkbox
                    v-model="history.answer"
                    class="flex-grow-1"
                    :label="history.title"
                    hide-details
                  />
                  <v-text-field
                    v-if="history.answer"
                    v-model="history.description"
                    class="flex-grow-0"
                    :label="history.descTitle"
                    hide-details
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <h2 class="blue-grey--text mt-6">
            {{ $t('Family History') }}
          </h2>
          <p>{{ $t('Have genetic members of your family had') }}</p>
          <v-row v-if="familyHistories.length">
            <v-col
              v-for="(history, idx) in familyHistories"
              :key="`fhistory-${idx}`"
              class="my-0 py-0 d-flex align-stretch"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="history.value"
                class="ma-0"
                :label="history.history"
                hide-details
              />
            </v-col>
          </v-row>

          <h2 class="blue-grey--text mt-6">
            {{ $t('Social History') }}
          </h2>

          <v-row>
            <v-col cols="12">
              <pp-select
                v-model="alcohol"
                :title="alcoholConsumptionLabel"
                :fields="occasions"
                slider-only
              />
            </v-col>
            <v-col cols="12">
              <pp-select
                v-model="sedative"
                :title="sedativeConsumptionLabel"
                :fields="occasions"
                slider-only
              />
            </v-col>
            <v-col cols="12">
              <pp-select
                v-model="caffeine"
                :title="caffeineConsumptionLabel"
                :fields="occasions"
                slider-only
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex align-stretch"
            >
              <v-card class="flex-grow-1">
                <v-card-text>
                  <v-checkbox
                    v-model="smoke"
                    class="my-0"
                    :label="smokeQuestion"
                    hide-details
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="smoke"
              cols="12"
              md="6"
            >
              <pp-select
                v-model="smokePacks"
                :title="amountOfSmokeQuestion"
                :min="0"
                :max="20"
              />
            </v-col>
            <v-col
              cols="12"
              :md="smoke ? 12 : 6"
            >
              <v-card class="flex-grow-1">
                <v-card-text>
                  <v-checkbox
                    v-model="tobacco"
                    class="my-0"
                    :label="chewingTobaccoQuestion"
                    hide-details
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-textarea
            v-model="additionalParagraph"
            :label="additionalParagraphLabel"
            class="mt-5"
            rows="4"
            outlined
          />
        </template>
      </common-loading>

      <template #actions>
        <v-btn to="/questionnaire/prev-treatments">
          <v-icon>mdi-chevron-double-left</v-icon>
          {{ $t('Back') }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          type="submit"
          :disabled="!isValidForm"
          :loading="loadingHistory ||
            submittingHistory ||
            loadingCompletedSections"
        >
          {{ qSections && qSections.history
            ? $t('Return Home') : $t('Submit') }}
        </v-btn>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import capitalize from 'lodash/capitalize'
import { utils } from 'ui-common'
import { useQuestionnaire } from '@/use/questionnaire'
import { useHistory } from '@/use/history'
import {
  occasions,
  dentalHealthQuality,
  dentalHistoryList,
  familyHistoryList
} from '@/constants/history'

export default {
  setup: (_, { root }) => {
    const {
      qSections,
      loadingCompletedSections
    } = useQuestionnaire()

    const {
      allergens,
      medications,
      medicalHistories,

      loadingHistory,
      submittingHistory,
      getHistory,
      submitHistory
    } = useHistory()

    const havePreMedication = ref(false)
    const preMedication = ref('')

    const haveAllergens = ref(false)
    const otherAllergens = ref('')

    const haveMedication = ref(false)
    const otherMedications = ref('')

    const dentalHistories = ref(dentalHistoryList.map(history => ({
      ...history,
      descTitle: 'Please describe',
      answer: false,
      description: ''
    })))
    const otherHistory = ref('')

    const dentalHealth = ref('')

    const familyHistories = ref(familyHistoryList.map(history => ({
      ...history,
      value: false
    })))

    const alcohol = ref(0)
    const sedative = ref(0)
    const caffeine = ref(0)
    const smoke = ref(false)
    const smokePacks = ref(0)
    const tobacco = ref(false)

    const additionalParagraph = ref('')

    const submitData = async () => {
      if (qSections.value.history) {
        utils.navigate('/')
        return
      }

      try {
        await submitHistory({
          havePreMedication: havePreMedication.value,
          preMedication: preMedication.value,

          haveAllergens: haveAllergens.value,
          otherAllergens: [
            ...allergens.value
              .filter(({ value }) => value)
              .map(({ allergens }) => allergens),
            otherAllergens.value
          ].join(', '),

          haveMedication: haveMedication.value,
          otherMedications: [
            ...medications.value
              .filter(({ value }) => value)
              .map(({ medications }) => medications),
            otherMedications.value
          ].join(', '),

          otherHistory: [
            ...medicalHistories.value
              .filter(({ value }) => value)
              .map(({ history }) => history),
            otherHistory.value
          ].join(', '),

          dentalHealth: dentalHealth.value,

          dentalHistories: dentalHistories.value,
          familyHistories: familyHistories.value,

          alcohol: occasions[alcohol.value],
          sedative: occasions[sedative.value],
          caffeine: occasions[caffeine.value],
          smoke: smoke.value,
          smokePacks: smokePacks.value,
          tobacco: tobacco.value,

          additionalParagraph: additionalParagraph.value
        })
        utils.navigate('/')
      } catch (err) {}
    }

    onMounted(getHistory)

    return {
      qSections,
      loadingCompletedSections,

      loadingHistory,
      submittingHistory,

      havePreMedication,
      preMedication,

      haveAllergens,
      otherAllergens,

      haveMedication,
      otherMedications,

      dentalHistories: computed(() =>
        dentalHistories.value.map(history => {
          history.title = root.$i18n.t(history.original)
          history.descTitle = root.$i18n.t('Please describe')
          return history
        })
      ),
      otherHistory,

      dentalHealth,
      dentalHealthQuality: computed(() =>
        dentalHealthQuality.map(element => {
          element.text = root.$i18n.t(element.value)
          return element
        })
      ),

      familyHistories: computed(() =>
        familyHistories.value.map(history => {
          history.history = root.$i18n.t(history.original)
          return history
        })
      ),

      occasions: computed(() =>
        occasions.map(occasion => {
          return root.$i18n.t(occasion)
        })
      ),

      medicationLabel: computed(() =>
        root.$i18n.t('What medication(s) and why do you require it?')
      ),
      allergicListLabel: computed(() =>
        root.$i18n.t('Please list everything you are allergic to:')
      ),
      medicationListLabel: computed(() =>
        root.$i18n
          .t('Please list all medications you are currently taking:')
      ),
      diagnosesListLabel: computed(() =>
        root.$i18n.t(
          'List all other medical diagnoses and surgeries from birth until now:'
        )
      ),
      dentalHealthLabel: computed(() =>
        root.$i18n.t('How would you describe your dental health?')
      ),
      alcoholConsumptionLabel: computed(() =>
        root.$i18n.t(
          'Alcohol consumption: ' +
          'How often do you consume alcohol within 2-3 hours of bedtime?'
        )
      ),
      sedativeConsumptionLabel: computed(() =>
        root.$i18n.t(
          'Sedative Consumption: ' +
          'How often do you take sedatives within 2-3 hours of bedtime?'
        )
      ),
      caffeineConsumptionLabel: computed(() =>
        root.$i18n.t(
          'Caffeine consumption: ' +
          'How often do you consume caffeine within 2-3 hours of bedtime?'
        )
      ),
      smokeQuestion: computed(() =>
        root.$i18n.t('Do you smoke?')
      ),
      amountOfSmokeQuestion: computed(() =>
        root.$i18n.t('If Yes, number of packs')
      ),
      chewingTobaccoQuestion: computed(() =>
        root.$i18n.t('Do you use chewing tobacco?')
      ),
      additionalParagraphLabel: computed(() =>
        root.$i18n.t('Additional Paragraph')
      ),

      alcohol,
      sedative,
      caffeine,
      smoke,
      smokePacks,
      tobacco,

      additionalParagraph,

      allergens,
      medications,
      medicalHistories,

      submitData
    }
  }
}
</script>
