import { ref, Ref } from '@vue/composition-api'
import repositories from '@/repositories'
import { arraySerializer } from '@/utils/answer'
import { parentProfile } from './profile'
import { useQuestionnaire } from './questionnaire'

export const useHistory = () => {
  const allergens: Ref<any[]> = ref([])
  const medications: Ref<any[]> = ref([])
  const medicalHistories: Ref<any[]> = ref([])
  const loadingHistory = ref(false)
  const submittingHistory = ref(false)

  const { getCompletedSections } = useQuestionnaire()

  const getHistory = async () => {
    loadingHistory.value = true
    try {
      allergens.value = (await repositories.allergens
        .getAllergens()).data
        .map((allergen: any) => ({
          ...allergen,
          value: false
        }))

      medications.value = (await repositories.medications
        .getMedications()).data
        .map((medication: any) => ({
          ...medication,
          value: false
        }))

      medicalHistories.value = (await repositories.medicalHistories
        .getMedicalHistories()).data.data
        .map((history: any) => ({
          ...history,
          value: false
        }))
    } catch (err) {} finally {
      loadingHistory.value = false
    }
  }

  const submitHistory = async ({
    havePreMedication,
    preMedication,

    haveAllergens,
    otherAllergens,

    haveMedication,
    otherMedications,

    otherHistory,

    dentalHealth,

    dentalHistories,
    familyHistories,

    alcohol,
    sedative,
    caffeine,
    smoke,
    smokePacks,
    tobacco,

    additionalParagraph
  }: any) => {
    submittingHistory.value = true
    const {
      patientid,
      userid,
      docid,
      sleepStatus,
      symptomsStatus,
      treatmentsStatus,
      lastHistory: { qPage3Id }
    } = parentProfile.value

    try {
      await repositories.healthHistory.updateHealthHistory({
        qPage3Id,
        info: {
          patientid,
          userid,
          docid,

          allergenscheck: haveAllergens,
          allergens: arraySerializer(allergens.value
            .filter(({ value }) => value)
            .map(({ allergensid }) => allergensid)),
          otherAllergens,

          medicationscheck: haveMedication,
          medications: arraySerializer(medications.value
            .filter(({ value }) => value)
            .map(({ medicationsid }) => medicationsid)),
          otherMedications,

          dentalHealth,
          ...dentalHistories.reduce((
            acc: any,
            { check, text, answer, description }: any
          ) => {
            check && (acc[check] = answer ? 'Yes' : 'No')
            text && (acc[text] = answer ? description : '')
            return acc
          }, {}),
          history: arraySerializer(medicalHistories.value
            .filter(({ value }) => value)
            .map(({ historyid }) => historyid)),
          otherHistory,

          ...familyHistories.reduce((acc: any, { id, value }: any) => {
            acc[id] = value ? 'Yes' : 'No'
            return acc
          }, {}),

          alcohol,
          sedative,
          caffeine,
          smoke: smoke ? 'Yes' : 'No',
          smokePacks: `${smokePacks}`,
          tobacco: tobacco ? 'Yes' : 'No',

          additionalParagraph,
          isCompleted: true
        }
      })

      if (sleepStatus >= 1 && symptomsStatus >= 1 && treatmentsStatus >= 1) {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: 2,
            sleepStatus: 2,
            treatmentsStatus: 2,
            historyStatus: 2
          })
        ).data
      } else {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: symptomsStatus,
            sleepStatus: sleepStatus,
            treatmentsStatus: treatmentsStatus,
            historyStatus: 1
          })
        ).data
      }

      await getCompletedSections()
    } catch (err) {} finally {
      submittingHistory.value = false
    }
  }

  return {
    allergens,
    medications,
    medicalHistories,

    loadingHistory,
    getHistory,

    submittingHistory,
    submitHistory
  }
}
