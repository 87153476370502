import constants from './index.json'
const {
  occasions,
  dentalHealthQuality,
  dentalHistoryList,
  familyHistoryList
} = constants

export {
  occasions,
  dentalHealthQuality,
  dentalHistoryList,
  familyHistoryList
}
